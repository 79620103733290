@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@600&display=swap");
* {
  font-smooth: smooth;
}
.navbar {
  background-color: #21042f57;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(3px);
  position: sticky;
  top: 0;
  text-shadow: #57c5ff;
  height: fit-content;
  z-index: 10;

}
.navbar.active {
  background-image: linear-gradient(to right, #04082b, #2f3074);
  animation: changeColour 2s ease;
}

@keyframes changeColour {
  0%,
  50% {
    background-color: transparent;
  }
  50%,
  100% {
    background-image: linear-gradient(to right, #04082b, #2f3074);
  }
}

.titleOfsite {
  margin-left: 12px;
  font-family: "Montserrat", "IBM Plex Mono", sans-serif;
  background: -webkit-linear-gradient(0deg, #ad00ad, #46b5f9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logicalspot {
  font-weight: 900;
  font-size: 36px;
}
.logicalspot.small {
  font-weight: 700;
  font-size: 30px;
}

.MenuItems {
  align-self: center;
}
.CornerArea {
  align-self: center;
  margin-right: 20px;
  right: 10px;
  /* background-color: aliceblue; */
  width: 90px;
  display: flex;
  justify-content: space-between;
}
.menuitm {
  font-size: large;
  font-weight: 600;
  padding-right: 3px;
  padding-left: 3px;
  text-shadow: 0 0 1rem #57c5ff;
  color: #3488c8;
  font-family: "Orbitron", monospace;
}
.nftBtn{
  /* background: none; */
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  /* background-image: url('../nfticon.png'); */
  height: 31px;
  width: 31px;
  opacity: 0.7;
  transition: all 0.3s ease;
}
.nftBtn:hover{
  opacity: 0.6;
}