.footer{
    bottom: 0px;
    right: 0px;
    position: fixed;
    font-size: large;
    font-weight: 500;
    background: -webkit-linear-gradient(0deg,#544e7a, #72aacd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-right: 10px;
  padding-bottom: 5px;
  filter: opacity(0.5);
    /* background-color: aliceblue; */
}