@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

.projectList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
  background-color: #100b20e7;
  border-radius: 20px;
  }

  .projectItems{
    display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; 
  margin-top: 20px;
  width: 100%;
  }

  .containerTtl {
    font-family: "Poppins";
    font-size: large;
    font-weight: 600;
    color: #739fff;
  }