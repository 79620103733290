@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
.latest {
  margin-top: 2rem;
  /* margin-left: 6rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: antiquewhite; */
  /* width: 40rem; */
  /* background-image: linear-gradient(to right,#04082b,#2f3074); */
}
.btns {
  margin-top: 1rem;
  display: flex; /* This makes the container a flex container */
  justify-content: center;
  width: 10rem;
}



.title {
  font-family: "Orbitron", sans-serif;
  width: 100%;
  font-weight: 800;
  color: #d3c8db;
  font-size: 35px;
  text-align: center;
  /* background-color: #d3c8db; */
}
.title.small {
  font-weight: 600;
  font-size: 35px;
}

.ttlFill {
  background-image: linear-gradient(-40deg, #1f77c4, #55188b);
  width: fit-content;
  font-family: "Montserrat", "IBM Plex Mono";
  font-weight: 900;
  color: #f0e2fa;
  font-size: 35px;
  border-radius: 19px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.ttlFill.small {
  font-size: 30px;
}

.infos {
  font-family: "Orbitron", sans-serif;
  color: #f5e7fe;
  text-align: start;
}
.BtnSignup {
  /* background-color: #3c86c7; */
  font-family: "Montserrat", "IBM Plex Mono";
  border-color: linear-gradient(#3c86c7, #3c3ec7);
}
.BtnSignup:hover,
.BtnSignup:focus {
  /* color: #313133; */
  box-shadow: 0 0 19px 0px rgb(190, 206, 255);
  /* text-shadow: 0 0 19px 0px rgb(255, 216, 190); */
  /* animation: rotate 1.5s linear infinite; */
  transform: translateY(-4px);
}
.BtnSignup:active {
  background: none;
  transform: translateY(-4px);
}

.buttonCont {
  /* display: flex; */

  width: max-content;
}
.buttonCont.small {
  min-width: 30px;
}



@keyframes rotate {
  0% {
    /* filter: hue-rotate(0deg); */
    border-color: linear-gradient(#3c86c7, #3c3ec7);
  }
  100% {
    border-color: linear-gradient(#ff550c, #c73c6f);
  }
}
