.projectcard {
    width: 300px;
    margin-bottom: 20px;
    display: flex;
  }

.cardSubTtl {
    margin-bottom: 10px;
    margin-left: 10px;
    max-height: 120px;
    overflow-x: hidden;
    margin-top: -10px;
    font-family: "Poppins";
    color: #f7e1fd;
    font-weight: 600;
}

/* hide scrollbar fo cardSubTtl */
.cardSubTtl::-webkit-scrollbar {
    display: none;
}

.imgcont {
    overflow: hidden;
    height: 180px;
    border-width: 5px;
    object-fit: cover;
    border-style: solid;
    border-color: #f7e1fd;
  }
  .imgcont img {
    width: 100%;
    transform: scale(1.009);
    transition: 0.4s all ease;
  }
  
  .imgcont:hover img {
    transform: scale(1.1);
  }