body,
html {
  height: 100%;
}

.App {
  /* text-align: center; */
  /* background-image: url("../public/cover4.png"); */
  /* background-image: linear-gradient(to bottom, #231e41 0%, #100751 100%); */
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover;
  /* position: fixed; */

  /* background-image: linear-gradient(#04082b,#2f3074); */
}
.App::-webkit-scrollbar {
  display: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-link {
  color: #61dafb;
}
