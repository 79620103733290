@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
.GlassBtn {
  font-family: "Montserrat", "IBM Plex Mono";
  background: transparent;
  height: 100%;
  min-height: 33px;
  color: #ffffff;
  font-size: 17px;
  font-weight: 500;
  border-color: #ffffff;
  border-style: solid;
  padding-left: 15px;
  padding-right: 15px;
  border-width: 1.6px;
  border-radius: 20px;
  box-shadow: 0 0 13px 0px rgb(190, 206, 255);
  transition: all 0.15s linear;
}
.GlassBtn:hover,
.GlassBtn:focus {
  /* color: #313133; */
  box-shadow: 0 0 20px 0px rgb(190, 206, 255);
  /* border-width: 2.5px; */
  transform: translateY(-4px);
}
.GlassBtn:active {
  background: none;
  transform: translateY(-4px);
}
.gbtndiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gbtnicon {
  margin-right: 8px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  /* padding: 5px; */
  /* background-color: #ffffff; */
}
